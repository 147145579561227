import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Subject } from 'rxjs';
import { AddCostVpDashTranslations } from './add-cost-modal.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { debounceTime, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getCurrencySymbol } from '@angular/common';
import {
  AccrualType,
  CostCategoryType,
  CostType,
  ExpenseType,
  CompetitorPickList,
  AccrualTypeId,
  CostResponse,
  CostSaveResponsePayload,
} from '@data/services/valueprop/models/cost.model';
import { ErrorMessagesService } from '@services/error-messages.service';
import { CustomValidators } from 'app/_utils/custom-validators';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-add-cost-modal',
  templateUrl: './add-cost-modal.component.html',
  styleUrls: ['./add-cost-modal.component.scss'],
})
export class AddCostModalComponent implements OnInit, OnDestroy {
  @Input('data') valueProp: ValueProp;
  @Input() cost: CostResponse;
  @Input() costId: string;
  @Input() edit: boolean;
  @Input() has_scratchpad: number | boolean;
  @Output() callback = new EventEmitter();

  account_id: any;
  accrualTypes: AccrualType[] = [];
  costCategoryTypes: CostCategoryType[];
  costTypes: CostType[] = [];
  expenseTypes: ExpenseType[] = [];
  fullImagePath: string;
  isSimplified = false;
  loading = false;
  ngUnsubscribe = new Subject();
  showTranslate = false;
  featureCompetitorPickList = false;
  competitorPickList: CompetitorPickList[];
  showCostTypeList = true;
  filteredCostTypeList: CompetitorPickList[] = [];
  hideDiscountFeature = false;
  termIndexArray: number[] = [];
  crm: string;
  advancedAccrualForm: FormGroup;
  currencySymbol = '';

  AccrualTypeId = AccrualTypeId;

  errorDict = new Map();
  errorConstructor = new Map();
  allowNegatives = this.commonService.checkFeature(138);
  advancedCostTypes = this.commonService.checkFeature(150);
  tco = this.commonService.checkFeature(10);
  precisionOf5 = this.commonService.checkFeature(157);
  

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private translationService: TranslationsV2Service,
    public trans: AddCostVpDashTranslations,
    private fb: FormBuilder,
    private errorMessageService: ErrorMessagesService
  ) {
    this.fullImagePath = this.commonService.getLoaderImageUrl();
  }

  ngOnInit() {
    this.setupForm();
    this.getTranslations();
    this.addExistingValues();
    this.setAllValidators(+this.cost.accrual_type_id);
    this.getCostCategoryTypes();
    this.getExpenseTypes();

    this.account_id = sessionStorage.getItem('aid');
    this.crm = sessionStorage.getItem('crm');
    this.isSimplified = this.commonService.checkFeature(97);
    this.featureCompetitorPickList = this.commonService.checkFeature(92);
    this.hideDiscountFeature = this.commonService.checkFeature(105);

    if (this.featureCompetitorPickList && !this.edit) {
      this.showCostTypeList = false;
    }

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    +this.has_scratchpad === 1 ? (this.has_scratchpad = true) : (this.has_scratchpad = false);

    this.termIndexArray = Array.from(Array(Number(this.valueProp.term)).keys()).map((yr) => yr + 1);

    this.valuepropService
      .getAvailableCosts(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.costTypes = response.result;
          if (!this.featureCompetitorPickList) {
            this.filteredCostTypeList = this.costTypes;
          } else if (this.edit) {
            this.filteredCostTypeList = this.costTypes;
            const currenCostType = this.costTypes.find((type) => type.id === this.cost.cost_type_id);
            this.cost.account_competitor_id = currenCostType.account_competitor_id;
          }
        }
      });

    this.valuepropService
      .getCompetitorList(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.competitorPickList = response.result;
        }
      });

    this.valuepropService
      .getAccrualTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.accrualTypes = response.result.filter((type) => type.id != 8);

          if ( !this.advancedCostTypes ) {
            this.accrualTypes = this.accrualTypes.filter((type) => type.id < 4);
          }
        }
      });

    if (this.cost === undefined && this.costId) {
      this.getCostDetail();
    }

    if (this.valueProp.currency_abbr) {
      this.currencySymbol = getCurrencySymbol(this.valueProp.currency_abbr, 'narrow');
      if (this.currencySymbol === '!') {
        this.currencySymbol = '$';
      }
    }

    this.advancedAccrualForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), startWith(this.advancedAccrualForm.value), debounceTime(100), pairwise())
      .subscribe((capitalLeasePairwise) => {
        this.errorDict = new Map(this.errorMessageService.getFormErrors(this.advancedAccrualForm.controls));
        if (
          (capitalLeasePairwise[0].initialCost !== capitalLeasePairwise[1].initialCost ||
            capitalLeasePairwise[0].annualInterestRate !== capitalLeasePairwise[1].annualInterestRate ||
            capitalLeasePairwise[0].leaseTerms !== capitalLeasePairwise[1].leaseTerms ||
            capitalLeasePairwise[0].quantity !== capitalLeasePairwise[1].quantity ||
            capitalLeasePairwise[0].discount !== capitalLeasePairwise[1].discount ||
            capitalLeasePairwise[0].residualValue !== capitalLeasePairwise[1].residualValue) &&
          +this.cost.accrual_type_id >= this.AccrualTypeId.CapitalLease
        ) {
          this.getCalculation(capitalLeasePairwise[1]);
        }
      });
  }

  setupForm() {
    this.advancedAccrualForm = this.fb.group({
      unitPriceCalculation: [''],
      initialCost: ['', [Validators.min(0)]],
      annualInterestRate: ['', [Validators.min(0)]],
      leaseTerms: '',
      quantity: [1],
      discount: [0, [Validators.min(0), Validators.max(100)]],
      buyoutValue: [0, [Validators.min(0)]],
      yoyGrowth: 0,
      unitPrice: 0,
      yr1Costs: 0,
      yr2Costs: 0,
      yr3Costs: 0,
      yr4Costs: 0,
      yr5Costs: 0,
      yr6Costs: 0,
      yr7Costs: 0,
      yr8Costs: 0,
      yr9Costs: 0,
      yr10Costs: 0,
      residualValue: [0, [Validators.min(0), Validators.max(100)]],
      categoryTypeId: [''],
      expenseTypeId: [''],
    });
  }

  getFormErrors() {
    const tempErrorsDict = new Map();

    Object.entries(this.advancedAccrualForm.controls).forEach(([control, value]) => {
      if (value.errors) {
        const errorType = Object.keys(value.errors)[0];
        let errorFunction: Function;
        if (control === 'leaseTerms' && errorType === 'max') {
          errorFunction = this.errorConstructor.get('term');
        } else {
          errorFunction = this.errorConstructor.get(errorType);
        }
        tempErrorsDict.set(control, errorFunction(value.errors[errorType][errorType]));
      }
    });
    this.errorDict = new Map(tempErrorsDict);
  }

  addExistingValues() {
    this.advancedAccrualForm.patchValue({
      initialCost: this.cost.present_value,
      annualInterestRate: this.cost.rate,
      leaseTerms: this.cost.lease_term,
      discount: this.cost.discount,
      quantity: this.cost.quantity ? this.truncateZeros(this.cost.quantity.toString()) : 1,
      residualValue: this.cost.residual_value,
      buyoutValue: this.cost.buyout_value,
      yoyGrowth: this.cost.rate ? this.cost.rate : 0,
      unitPrice: this.cost.cost ? this.cost.cost : 0,
      yr1Costs: this.cost.yr1_costs ? this.cost.yr1_costs : 0,
      yr2Costs: this.cost.yr2_costs,
      yr3Costs: this.cost.yr3_costs,
      yr4Costs: this.cost.yr4_costs,
      yr5Costs: this.cost.yr5_costs,
      yr6Costs: this.cost.yr6_costs,
      yr7Costs: this.cost.yr7_costs,
      yr8Costs: this.cost.yr8_costs,
      yr9Costs: this.cost.yr9_costs,
      yr10Costs: this.cost.yr10_costs,
      expenseTypeId: this.cost.alt_expense_type_id ? this.cost.alt_expense_type_id : this.cost.expense_type_id,
      categoryTypeId: this.cost.cost_category_type_id,
    });
    this.clearValidators();
    this.setAllValidators(this.cost.accrual_type_id);
    if (!this.valueProp.vp_can_edit) {
      this.advancedAccrualForm.disable();
    }

    if (this.cost.accrual_type_id >= AccrualTypeId.CapitalLease) {
      this.getCalculation(this.advancedAccrualForm.value);
    }
  }

  truncateZeros(value: string): string {
    const strVal = value.toString();
    if (strVal.split('.')[1] === '00') {
      return value.split('.')[0];
    }
    return value;
  }

  getCalculation(formValues) {
    const payload = {
      ...this.cost,
      residual_value: formValues.residualValue,
      present_value: formValues.initialCost,
      rate: formValues.annualInterestRate,
      lease_term: formValues.leaseTerms,
      quantity: formValues.quantity,
      discount: formValues.discount,
    };

    this.valuepropService
      .calculateAdvancedAccrualCosts(this.valueProp.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.advancedAccrualForm.patchValue(
            {
              unitPriceCalculation: +response.result.cost,
            },
            {
              emitEvent: false,
            }
          );
        }
      });
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  costTypeChanged(costId: string) {
    const foundCost = this.costTypes.find((cost) => cost.id === costId);

    this.cost = {
      ...foundCost,
      alt_cost_name: foundCost.name ? foundCost.name : '',
      alt_cost_description: foundCost.description ? foundCost.description : '',
      discount: 0,
      cost_type_id: costId,
      value_prop_cost_id: this.cost.id,
    };

    this.termIndexArray.forEach((yrIndex) => {
      const costProperty = `yr${yrIndex}_costs`;
      if (foundCost.hasOwnProperty(costProperty)) {
        this.cost[costProperty] = foundCost[costProperty];
      }
    });
    this.addExistingValues();
  }

  closeSidebar() {
    this.callback.next();
  }

  setCompetitorCostTypeList(competitorId: string) {
    if (competitorId) {
      this.filteredCostTypeList = this.costTypes.filter((type) => type.account_competitor_id == competitorId);
      this.showCostTypeList = true;
    }
  }

  getCostDetail() {
    this.valuepropService
      .getCostDetail(this.valueProp.id, this.costId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: { result: CostResponse }) => {
        if (response.result) {
          const cost = response.result;

          this.cost = {
            ...cost,
            value_prop_id: this.valueProp.id,
            value_prop_cost_id: cost.id,
          };
        }
      });
  }

  getExpenseTypes() {
    this.factsService
      .getExpenseTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (!this.isSimplified) {
          this.expenseTypes = result.result;
        }
      });
  }

  getCostCategoryTypes() {
    this.factsService
      .getAccountCostCategoryTypes(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.costCategoryTypes = result.result;
      });
  }

  setAllValidators(accrualType: number) {
    switch (+accrualType) {
      case this.AccrualTypeId.CapitalLease:
        this.advancedAccrualForm.controls.initialCost.setValidators([Validators.required, Validators.min(1)]);
        this.advancedAccrualForm.controls.discount.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        this.advancedAccrualForm.controls.annualInterestRate.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        this.advancedAccrualForm.controls.leaseTerms.setValidators([Validators.required, Validators.min(1), CustomValidators.maxTermValidator(+this.valueProp.term * 12)]);
        break;
      case this.AccrualTypeId.OneTime:
        this.advancedAccrualForm.controls.unitPrice.setValidators([Validators.required]);
        if (!this.allowNegatives) {
          this.advancedAccrualForm.controls.unitPrice.addValidators(Validators.min(this.precisionOf5 ? 0.00001 : 0.01));
        }
        this.advancedAccrualForm.controls.discount.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        break;

      case this.AccrualTypeId.Annual:
        this.advancedAccrualForm.controls.unitPrice.setValidators([Validators.required]);
        this.advancedAccrualForm.controls.yoyGrowth.setValidators([Validators.required]);
        if (!this.allowNegatives) {
          this.advancedAccrualForm.controls.unitPrice.addValidators(Validators.min(this.precisionOf5 ? 0.00001 : 0.01));
          this.advancedAccrualForm.controls.yoyGrowth.addValidators(Validators.min(0));
        }
        this.advancedAccrualForm.controls.discount.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        break;

      case this.AccrualTypeId.FairMarket:
        this.advancedAccrualForm.controls.initialCost.setValidators([Validators.required, Validators.min(1)]);
        this.advancedAccrualForm.controls.residualValue.setValidators([Validators.required, Validators.min(1)]);
        this.advancedAccrualForm.controls.buyoutValue.setValidators([Validators.required, Validators.min(1)]);
        this.advancedAccrualForm.controls.discount.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        this.advancedAccrualForm.controls.annualInterestRate.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
        this.advancedAccrualForm.controls.leaseTerms.setValidators([Validators.required, Validators.min(1), CustomValidators.maxTermValidator(+this.valueProp.term * 12)]);
        break;
      default:
        break;
    }
    if (this.commonService.checkFeature(128)) {
      this.advancedAccrualForm.controls.quantity.setValidators([Validators.required, Validators.min(0), CustomValidators.twoDecimalValidator()]);
    } else {
      this.advancedAccrualForm.controls.quantity.setValidators([Validators.required, Validators.min(0), CustomValidators.noDecimalValidator()]);
    }
  }

  clearValidators(): void {
    for (const key in this.advancedAccrualForm.controls) {
      if (key !== 'quantity') {
        this.advancedAccrualForm.get(key).clearValidators();
        this.advancedAccrualForm.get(key).updateValueAndValidity();
      }
    }
  }

  accrualChange(accrualType: AccrualTypeId) {
    this.clearValidators();

    if (+accrualType === AccrualTypeId.Variable && this.cost.cost !== '') {
      const annualCost = +this.cost.cost / this.termIndexArray.length;
      this.termIndexArray.forEach((termIndex) => {
        this.cost['yr' + termIndex + '_costs'] = annualCost;
      });
    } else {
      this.cost.yr1_costs = '';
      this.cost.yr2_costs = '';
      this.cost.yr3_costs = '';
    }

    this.setAllValidators(+accrualType);

    this.addExistingValues();
  }

  saveCosts() {
    this.loading = true;

    if (+this.cost.accrual_type_id === AccrualTypeId.Variable && !this.cost.cost) {
      this.cost.cost = '1';
    }
    this.cost.cost_category_type_id = this.cost.cost_category_type_id;

    if (+this.cost.accrual_type_id === AccrualTypeId.CapitalLease) {
      this.assignFormValues();
    } else if (+this.cost.accrual_type_id === AccrualTypeId.FairMarket) {
      this.assignFormValues();
      this.cost.residual_value = this.advancedAccrualForm.value.residualValue;
      this.cost.buyout_value = this.advancedAccrualForm.value.buyoutValue;
    } else if (+this.cost.accrual_type_id === AccrualTypeId.Annual) {
      this.assignFormValues();
      this.cost.cost = this.advancedAccrualForm.value.unitPrice;
      this.cost.rate = this.advancedAccrualForm.value.yoyGrowth;
    } else if (+this.cost.accrual_type_id === AccrualTypeId.Variable) {
      this.assignFormValues();
      this.cost.yr1_costs = this.advancedAccrualForm.value.yr1Costs;
      this.cost.yr2_costs = this.advancedAccrualForm.value.yr2Costs;
      this.cost.yr3_costs = this.advancedAccrualForm.value.yr3Costs;
      this.cost.yr4_costs = this.advancedAccrualForm.value.yr4Costs;
      this.cost.yr5_costs = this.advancedAccrualForm.value.yr5Costs;
      this.cost.yr6_costs = this.advancedAccrualForm.value.yr6Costs;
      this.cost.yr7_costs = this.advancedAccrualForm.value.yr7Costs;
      this.cost.yr8_costs = this.advancedAccrualForm.value.yr8Costs;
      this.cost.yr9_costs = this.advancedAccrualForm.value.yr9Costs;
      this.cost.yr10_costs = this.advancedAccrualForm.value.yr10Costs;
    } else if (+this.cost.accrual_type_id === AccrualTypeId.OneTime) {
      this.cost.cost = this.advancedAccrualForm.value.unitPrice;
      this.cost.quantity = this.advancedAccrualForm.value.quantity ? this.advancedAccrualForm.value.quantity : '1';
      this.cost.discount = this.advancedAccrualForm.value.discount;
      this.cost.alt_expense_type_id = this.advancedAccrualForm.value.expenseTypeId;
      this.cost.cost_category_type_id = this.advancedAccrualForm.value.categoryTypeId;
    }
    const costType = this.costTypes.find((cost) => this.cost.cost_type_id === cost.id);
    this.cost['account_competitor_id'] = costType.account_competitor_id;

    if (this.cost.cost_category_type_id === '') {
      this.cost.cost_category_type_id = '0';
    }

    this.cost.value_prop_id = this.valueProp.id;

    if (+this.cost.accrual_type_id === AccrualTypeId.Variable && !this.cost.cost) {
      (this.cost.cost as number) = 1;
    }
    (this.cost.cost_category_type_id as number) = +this.cost.cost_category_type_id;

    if (+this.cost.accrual_type_id === AccrualTypeId.CapitalLease) {
      this.assignFormValues();
    } else if (+this.cost.accrual_type_id === AccrualTypeId.FairMarket) {
      this.assignFormValues();
      this.cost.residual_value = this.advancedAccrualForm.value.residualValue;
      this.cost.buyout_value = this.advancedAccrualForm.value.buyoutValue;
    } else if (+this.cost.accrual_type_id === AccrualTypeId.Annual) {
      this.assignFormValues();
      this.cost.cost = this.advancedAccrualForm.value.unitPrice;
      this.cost.rate = this.advancedAccrualForm.value.yoyGrowth;
    } else if (+this.cost.accrual_type_id === AccrualTypeId.Variable) {
      this.assignFormValues();
      this.cost.yr1_costs = this.advancedAccrualForm.value.yr1Costs;
      this.cost.yr2_costs = this.advancedAccrualForm.value.yr2Costs;
      this.cost.yr3_costs = this.advancedAccrualForm.value.yr3Costs;
      this.cost.yr4_costs = this.advancedAccrualForm.value.yr4Costs;
      this.cost.yr5_costs = this.advancedAccrualForm.value.yr5Costs;
      this.cost.yr6_costs = this.advancedAccrualForm.value.yr6Costs;
      this.cost.yr7_costs = this.advancedAccrualForm.value.yr7Costs;
      this.cost.yr8_costs = this.advancedAccrualForm.value.yr8Costs;
      this.cost.yr9_costs = this.advancedAccrualForm.value.yr9Costs;
      this.cost.yr10_costs = this.advancedAccrualForm.value.yr10Costs;
    } else if (+this.cost.accrual_type_id === AccrualTypeId.OneTime) {
      this.cost.cost = this.advancedAccrualForm.value.unitPrice;
      this.cost.quantity = this.advancedAccrualForm.value.quantity ? this.advancedAccrualForm.value.quantity : '1';
      this.cost.discount = this.advancedAccrualForm.value.discount;
      this.cost.alt_expense_type_id = this.advancedAccrualForm.value.expenseTypeId;
      this.cost.cost_category_type_id = this.advancedAccrualForm.value.categoryTypeId;
    }

    // Ensure discount is 0 and not an empty string, cost will not save if discount is not a number
    this.cost.discount = !this.advancedAccrualForm.value.discount ? 0 : this.cost.discount;

    if (this.edit) {
      this.valuepropService
        .editCost(this.valueProp.id, this.cost)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.handleSaveResponse(response);
        });
    } else {
      this.valuepropService
        .addCost(this.valueProp.id, this.cost)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.handleSaveResponse(response);
        });
    }
  }
  handleSaveResponse(response: CostSaveResponsePayload) {
    if (response.result && response.result.success !== false) {
      this.valuepropService.refreshCosts.next();
      const message = this.edit ? this.trans.trans.costUpdated.value : this.trans.trans.costAdded.value;
      this.notificationService.success(message, false);
    } else if (response.result.success === false) {
      this.notificationService.error(response.result.message, false);
    }
    this.loading = false;

    this.valuepropService.refreshDashboard.next();
    this.callback.emit();
  }

  assignFormValues() {
    this.cost.lease_term = this.advancedAccrualForm.value.leaseTerms;
    this.cost.present_value = this.advancedAccrualForm.value.initialCost;
    this.cost.rate = this.advancedAccrualForm.value.annualInterestRate;
    this.cost.quantity = this.advancedAccrualForm.value.quantity ? this.advancedAccrualForm.value.quantity : '1';
    this.cost.discount = this.advancedAccrualForm.value.discount;
    this.cost.alt_expense_type_id = this.advancedAccrualForm.value.expenseTypeId;
    this.cost.cost_category_type_id = this.advancedAccrualForm.value.categoryTypeId;
  }

  expenseCostTrackBy(_index: number, object: { id: string }) {
    return object.id;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
